import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from 'gatsby'
import { StaticImage, GatsbyImage, getImageData, getSrc, getImage } from 'gatsby-plugin-image'
import { HiOutlineArrowNarrowRight } from '@react-icons/all-files/hi/HiOutlineArrowNarrowRight'
import { HiOutlineArrowDown } from '@react-icons/all-files/hi/HiOutlineArrowDown'
import { GoLinkExternal } from '@react-icons/all-files/go/GoLinkExternal'

import FullLayout from '../components/layout/fulllayout'
import FadeInSection from '../components/fadeinsection'
import ResponsiveHeader from '../components/responsiveheader'
import SEO from '../components/seo'
import Form from "../components/form"

import content from "../content/the-arctic-vault.json"
import { R, BG_IMG_CLASSES } from "../helpers/utils"
import usePoller from "../hooks/usePoller"

const TITLE = R(content.title);

const ContactForm = () => {
    const [formState, setFormState] = useState({
        name: '',
        email: '',
        project: '',
        description: '',

        _canSubmit: false,
        _success: false,
        _buttonText: "Let's go! 🥶"
    });

    const handleFormChange = (event) => {
        setFormState(prevState => ({
            ...prevState,
            _canSubmit: formState.name.length >= 3 && formState.email.length >= 3 && formState.description.length > 10,
            [event.target.name]: event.target.value
        }))
    }

    const handleFormSuccess = () => {
        setFormState(prevState => ({...prevState, _success: true, _buttonText: "Thank you!" }))
    }

    return (
        <Form formName="arcticvault" formState={formState} onFormSuccess={handleFormSuccess}>
            <div className="flex flex-col md:flex-row mb-6">
                <div className="mb-6 md:mb-0 mr-0 md:mr-6">
                    <label className="block font-medium text-gray-300 mb-2">Your name <span className="text-red-600">*</span></label>
                    <input
                        type="text"
                        name="name"
                        required={true}
                        minLength={3}
                        maxLength={24}
                        onChange={handleFormChange}
                        disabled={formState._success}
                        className={`w-full h-12 lg:h-14 text-gray-800 bg-gray-200 py-2 rounded-md border-none px-4 text-sm md:text-base ${formState._success && "cursor-not-allowed opacity-50"}`}
                    />
                </div>

                <div className="mb-6 md:mb-0 mr-0 md:mr-6">
                    <label className="block font-medium text-gray-300 mb-2">Your e-mail address <span className="text-red-600">*</span></label>
                    <input
                        type="text"
                        name="email"
                        required={true}
                        minLength={3}
                        maxLength={24}
                        onChange={handleFormChange}
                        disabled={formState._success}
                        className={`w-full h-12 lg:h-14 text-gray-800 bg-gray-200 py-2 rounded-md border-none px-4 text-sm md:text-base ${formState._success && "cursor-not-allowed opacity-50"}`}
                    />
                </div>

                <div className="mb-6 md:mb-0 mr-0 md:mr-6">
                    <label className="block font-medium text-gray-300 mb-2">Project name</label>
                    <input
                        type="text"
                        name="project"
                        minLength={3}
                        maxLength={24}
                        onChange={handleFormChange}
                        disabled={formState._success}
                        className={`w-full h-12 lg:h-14 text-gray-800 bg-gray-200 py-2 rounded-md border-none px-4 text-sm md:text-base ${formState._success && "cursor-not-allowed opacity-50"}`}
                    />
                </div>
            </div>

            <div>
                <label className="block font-medium text-gray-300 mb-2">Why should your works be included in the vault? <span className="text-red-600">*</span></label>
                <textarea
                    name="description"
                    rows={3}
                    required={true}
                    onChange={handleFormChange}
                    disabled={formState._success}
                    className={`w-full text-gray-800 bg-gray-200 py-2 rounded-md border-none px-4 text-sm md:text-base ${formState._success && "cursor-not-allowed opacity-50"}`}
                ></textarea>
            </div>

            <button
                type="submit"
                disabled={!formState._canSubmit}
                className={`text-md md:text-2xl rounded-full font-bold mt-6 py-2 px-4 md:px-6 md:py-3 shadow-xl levitate ${formState._success ? "button-green" : "button-blue"} ${!formState._canSubmit && "cursor-not-allowed opacity-50"}`}
            >
                {formState._buttonText}
            </button>
        </Form>
    )
}

const IndexPage = () => {
    const [activeProjectIndex, setActiveProjectIndex] = useState(0)

    usePoller(() => {
        setActiveProjectIndex(prevIndex => prevIndex == (content.projects.length - 1)  ? 0 : prevIndex + 1)
    }, { interval: 5000 })

    const { query: { edges: images } } = useStaticQuery(graphql`
        {
            query: allFile(filter: { relativeDirectory: { eq: "arcticvault" } }) {
                edges {
                  node {
                    name
                      childImageSharp {
                          gatsbyImageData(layout: FULL_WIDTH)
                      }
                  }
                }
            }
        }
    `)

    return (
        <FullLayout>
            <SEO title="Non-Fungible Vault" description={TITLE} image={getSrc(images.find(i => i.node.name == "banner").node)} />

            <div className="w-full container grid mx-auto mb-6 px-4 md:px-8 h-screen">
                <StaticImage
                    src="../images/arcticvault/mountains.jpg"
                    className={`left-1/2 right-1/2 h-screen w-screen -ml-half-screen -mr-half-screen -mt-48 opacity-75`}
                    loading="eager"
                    layout="fullWidth"
                    style={{ gridArea: '1/1' }}
                />
                <div
                    className={`absolute inset-0 bg-gradient-to-t from-black to-transparent h-screen`}
                    style={{ gridArea: "1/1" }}
                ></div>

                <div
                    className="grid w-full max-w-screen-lg mx-auto"
                    style={{ gridArea: "1/1" }}
                >
                    <FadeInSection>
                        <div className="text-center">
                            <StaticImage src="../images/arcticvault/avlogo.png" width={128} className="mb-8" />
                            <ResponsiveHeader className="font-black mb-16" size={4}>{TITLE}</ResponsiveHeader>

                            <iframe height="480" src="https://www.youtube-nocookie.com/embed/sM0G9KDtfd4?controls=0" className="w-full mx-auto" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </FadeInSection>
                </div>
            </div>
            
            <div className="bg-black text-white pb-48 px-8 md:px-16 lg:px-32" id="arcticvault">
                <FadeInSection delay="0.45s">
                    <div className="container flex flex-col lg:flex-row mx-auto">
                        <div className="w-full lg:w-1/2 mr-0 mb-10 lg:mb-0 lg:mr-6">
                            <ResponsiveHeader size={4} className="uppercase font-black mb-6 bw-effect">
                                <span>NON-FUNGIBLE VAULT.</span>
                                <span>NON-FUNGIBLE VAULT.</span>
                            </ResponsiveHeader>

                            <p className="text-2xl md:text-2xl lg:text-3xl lg:leading-tight font-bold mb-6">
                                Designed to withstand natural and man-made disasters, in the safest location on Earth, NFTs stored here will last for centuries.
                            </p>

                            <p className="text-lg text-gray-200 mb-10">
                                We're etching NFTs <em>(including the art and provenance information)</em> onto special purpose-built silver-halide film that is designed to last for over 1,000 years.
                                The films are then stored in a decommissioned coal mine in the Svalbard archipelago, known as the Arctic World Archive.
                            </p>

                            <Link href="/blog/introducing-the-top-dog-non-fungible-vault/" className="inline-block button-outline font-bold transition-colors duration-300 ease-in-out levitate px-2 md:px-4 text-md md:text-2xl items-center focus:outline-none">
                                <span className="inline-block py-3 ml-4">Read More <HiOutlineArrowNarrowRight className="inline mr-4" size="1.5em" /></span>
                            </Link>
                        </div>

                        <div className="w-full lg:w-1/2 ml-0 mt-6 lg:mt-0 lg:ml-6">
                            <div className="relative">
                                <StaticImage
                                    src="../images/arcticvault/awa.jpg"
                                    className="object-cover w-full opacity-25" style={{ height: "512px" }}
                                    alt="Arctic World Archive"
                                />

                                <div className="text-sm md:text-base text-gray-300 absolute bottom-0 p-6 w-full">
                                    <p className="mb-6">Established in 2017, the Arctic World Archive (AWA) already holds an impressive collection of valuable digital artefacts and irreplaceable information from around the world.</p>
                                    <p className="mb-6">The AWA is home to manuscripts from the Vatican Library, political histories, scientific history from the European Space Agency, masterpieces from different eras (including Rembrandt and Munch), scientific breakthroughs and contemporary cultural treasures from around the world.</p>
                                    <p>And now some of the most influential and culturally significant digital art will become a part of human history.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </FadeInSection>
            </div>

            <div className="bg-white text-black py-48 px-8 md:px-16 lg:px-32" id="permanence">
                <FadeInSection delay="0.25s">
                    <div className="container flex flex-col lg:flex-row mx-auto">
                        <div className="w-full lg:w-1/2 mr-0 mb-10 lg:mb-0 lg:mr-6">
                            <ResponsiveHeader size={3} className="uppercase font-black mb-6 bw-effect">
                                <span>THE PERMANENCE PROBLEM.</span>
                                <span>THE PERMANENCE PROBLEM.</span>
                            </ResponsiveHeader>

                            <p className="mb-6">Traditional art has stood the test of time with pieces created some 45,000 years ago still in existence, yet NFTs minted 45 days ago are already missing.</p>
                            <p className="mb-6">We truly believe that NFTs are here to stay and will shape a new creator economy to become some of the most influential and culturally significant works of our time. But some of these works are at risk of being lost forever.</p>
                            <p>That's why we are creating the Non-Fungible Vault—<strong>to protect provenance by safely storing these works for generations to come.</strong></p>
                        </div>

                        <div className="w-full lg:w-1/2  ml-0 mt-6 lg:mt-0 lg:ml-6">
                            <StaticImage
                                src="../images/arcticvault/icebergs.jpg"
                                className="object-cover w-full h-96"
                                objectPosition="50% 60%"
                                alt="Arctic World Archive"
                            />
                        </div>
                    </div>
                </FadeInSection>
            </div>

            <div className="bg-black text-white py-48 px-8 md:px-16 lg:px-32">
                <FadeInSection delay="0.35s">
                    <div className="container flex flex-col lg:flex-row mx-auto">
                        <div className="w-full lg:w-1/2 mr-0 mb-6 lg:mb-0 lg:mr-6">
                            <div className="bg-white p-12 text-black h-full">
                                <ResponsiveHeader size={3} className="font-black mb-6">Provenance</ResponsiveHeader>
                                <p className="text-2xl text-gray-400 pb-6 border-b border-gray-600">/ˈprɒv(ə)nəns/ <span className="ml-12">noun</span></p>
                                <p className="text-xl mt-6">a record of ownership of a work of art or an antique, used as a guide to authenticity or quality.</p>
                            </div>
                        </div>

                        <div className="w-full lg:w-1/2 ml-0 mb-10 lg:mb-0 lg:ml-6">
                            <ResponsiveHeader size={3} className="uppercase font-black mb-6 bw-effect">
                                <span>PROTECTING PROVENANCE.</span>
                                <span>PROTECTING PROVENANCE.</span>
                            </ResponsiveHeader>

                            <p className="mb-6">If we were to sum up NFTs in one word it would be that—<strong>provenance</strong>.</p>
                            <p className="mb-6">Provenance that saw the birth of the metaverse, that defined an explosion of creative digital expression, and that will be culturally significant in generations to come.</p>
                            <p className="mb-6">We believe it is important to protect that provenance—from the on-chain records through to the beautiful artwork and creations themselves.</p>
                            <p>The Non-Fungible Vault will help to communicate the story and creative expression of our world to future generations, much like the <a href="https://voyager.jpl.nasa.gov/golden-record/" target="_blank">golden records of the Voyager 1 and 2 spacecrafts</a>.</p>
                        </div>
                    </div>
                </FadeInSection>
            </div>

            <div className="bg-white text-black py-48 px-8 md:px-16 lg:px-32">
                <FadeInSection delay="0.55s">
                    <div className="container flex flex-col lg:flex-row mx-auto">
                        <div className="w-full lg:w-1/3 text-lg text-justify leading-8 mr-0 mb-10 lg:mb-0 lg:mr-10">
                            <ResponsiveHeader size={3} className="uppercase font-black mb-6 bw-effect">
                                <span>INSIDE THE VAULT.</span>
                                <span>INSIDE THE VAULT.</span>
                            </ResponsiveHeader>

                            <p className="mb-6">We've partnered with the following projects and artists to form "COHORT ONE" and bring their works to the Non-Fungible Vault. They share our 1,000-year vision and are passionate about protecting their provenance.</p>
                            <p className="mb-6">The first deposits took place in early 2022 and we are now inviting additional partners to form COHORT TWO.</p>

                            <div className="bg-black p-4 mb-4">
                                <p className="uppercase text-gray-400 text-xs"># OF NFTS IN THE VAULT</p>
                                <p className="text-2xl font-bold text-white">67,531</p>
                            </div>

                            <div className="bg-black p-4 mb-4">
                                <p className="uppercase text-gray-400 text-xs">TOTAL VAULT MARKET CAP.</p>
                                <p className="text-2xl font-bold text-white">$763,144,131</p>
                            </div>

                            <div className="bg-black p-4">
                                <p className="uppercase text-gray-400 text-xs">TOTAL VAULT TRADING VOL.</p>
                                <p className="text-2xl font-bold text-white">$5,927,331,241</p>
                            </div>
                        </div>

                        <div className="w-full lg:w-2/3 ml-0 mt-10 lg:mt-0 lg:ml-10 text-lg md:text-xl font-black">
                            <div className="flex h-full">
                                <p className="transform inline-block text-3xl md:text-6xl" style={{ writingMode: "vertical-lr", textOrientation: "mixed" }}>COHORT ONE</p>

                                <div className="flex-1 bg-black w-auto text-white shadow-xl">
                                    <div className="">
                                        {/* <div className="w-full">
                                            <GatsbyImage
                                                image={images.find(i => i.node.name == content.projects[activeProjectIndex].key).node.childImageSharp.gatsbyImageData}
                                                className="object-fill opacity-25"
                                            />
                                        </div> */}

                                        <div className="w-full">
                                            {content.projects.map((project, index) =>
                                                <div className={`shadow-lg ${activeProjectIndex == index ? "bg-white" : ""} transition-all ease-in-out duration-250`}>
                                                    <div className="px-8 md:px-12 py-4"> 
                                                        <p className="block mb-1">
                                                            <a href={project.link} target="_blank" className={activeProjectIndex == index ? "text-black" : "text-white"}>
                                                                <span>{project.title}</span>
                                                                <GoLinkExternal className="ml-2 inline-block" size="1.25em" />
                                                            </a>
                                                        </p>
                                                        <p className={`text-xs md:text-sm font-normal ${activeProjectIndex == index ? "text-gray-700" : "text-gray-300"}`}>{project.description}</p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </FadeInSection>
            </div>

            <div className="bg-black py-48 px-8 md:px-16 lg:px-32" id="contact">
                <div className="container mx-auto">
                    <ResponsiveHeader size={3} className="uppercase font-black mb-6 bw-effect">
                        <span>JOIN THE VAULT.</span>
                        <span>JOIN THE VAULT.</span>
                    </ResponsiveHeader>

                    <p className="mb-12">Become a part of human history with your works stored alongside manuscripts from the Vatican Library and significant scientific breakthroughs..</p>
                    <ContactForm />
                </div>
            </div>
        </FullLayout>
    )
}

export default IndexPage