import React from "react"

const noop = () => {};
const error = () => { alert('There was an error. Please try again') }; // todo: handle this better

const Form = ({ children, formName, formState, formHint, onFormSuccess = noop, onFormError = error }) => {
    const encodeFormData = (data) => {
        return Object.keys(data)
            .filter((key, i) => !key.startsWith('_'))
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }

    const handleFormSubmit = (event) => {
        // magically handled by netlify forms (see https://docs.netlify.com/forms/setup/)
        // we might want to change this in the future
        fetch("/", {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: encodeFormData({ "form-name": formName, ...formState })
            })
            .then(() => onFormSuccess())
            .catch(() => onFormError());

        event.preventDefault();
    }

    return (
        <div className="flex flex-col justify-around">
            <form name={formName} method="POST" data-netlify="true" onSubmit={handleFormSubmit}>
                {children}
            </form>

            {formHint && <div className="text-sm mt-1 text-gray-600">{formHint}</div>}
        </div>
    )
}

export default Form